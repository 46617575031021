.slideshow-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height */
  overflow: hidden;
}

.slideshow-slide {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.slideshow-slide:nth-child(1) {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .slideshow-slide {
    background-size: cover; /* Adjust to fit portrait images */
    background-position: top;
  }
}
