.showtimes-container {
  display: flex;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.movie-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  text-align: center;
}

.movie-poster {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: #001f3f;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #4e93d800;
  color: #fff;
  border: none;
  width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
}


.trailer-video {
  width: 100%;
  height: auto;
  max-height: 400px;
}

.time-slots {
  margin-top: 20px;
}

.time-slot {
  background: #1477c9a8;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}








