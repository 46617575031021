.nowplaying-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Ensure full height or more depending on the content */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  padding: 20px 0; /* Add padding for spacing */
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.movies-list {
  position: relative;
  display: flex;
  flex-direction: row; /* Default to row for desktop */
  align-items: center;
  width: 90%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap; /* Prevent wrapping in desktop view */
}

.movie-item {
  flex: 0 0 auto;
  width: 250px;
  margin-right: 20px;
  scroll-snap-align: center;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.movie-item:hover {
  transform: scale(1.1);
}

.movie-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 10px;
}

.movie-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.movie-title {
  margin: 0;
  font-size: 1.2em;
  color: white;
}

.movie-runtime-rating {
  margin: 5px 0;
  color: white;
}

.get-tickets-button {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: skyblue;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.get-tickets-button:hover {
  background-color: deepskyblue;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .nowplaying-container {
    padding: 20px 10px; /* Reduce padding for mobile */
    min-height: auto; /* Adjust height to fit content */
  }

  .movies-list {
    flex-direction: column; /* Switch to column layout on mobile */
    align-items: center;
    overflow-x: visible; /* Ensure all items are visible */
    width: 100%; /* Full width on mobile */
  }

  .movie-item {
    width: 90%; /* Increase width for better visibility on mobile */
    margin-right: 0; /* Remove margin-right */
    margin-bottom: 20px; /* Add margin between items */
  }

  /* Hide background image for mobile devices */
  .background-image {
    display: none;
  }
}
