/* StickyBarRight.css */

.sticky-bar-right {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 700px; /* Adjust width as needed */
  background-color: #000; /* Background color to cover empty space */
  overflow: hidden; /* Hide overflow if needed */
}

.sticky-bar-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-bar-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Cover the entire sidebar */
  object-fit: cover; /* Ensure the video covers the container while maintaining aspect ratio */
}

.trailer-video-background {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
