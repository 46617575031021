/* Header Styles */
.navbar {
  width: 100%;
  position: relative;
  z-index: 1000;
  background-color:#001f3f;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;

}

.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-logo {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

.menu-icon {
  display: none;
  font-size: 28px;
  color: #ffffff;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
}

.nav-item {
  margin: 0 10px;
}

.nav-links {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 16px;
  transition: all 0.3s ease;
}

.nav-links:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.nav-links-mobile {
  display: none;
}

.secondary-header {
  width: 100%;
  background-color: #001f3f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-top: 10px;
  border-top: 2px solid #ffffff;
}

.get-tickets {
  color: #ffffff;
  font-weight: bold;
}

.header-links a {
  margin: 0 10px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #87CEEB, #8A2BE2);
  padding: 20px 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
}

.now-playing-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.now-playing-image {
  width: 100%;
  border-radius: 10px;
}

.category-container {
  width: 25%;
  text-align: left;
}

.category-link {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
  margin-bottom: 10px;
  display: block;
}

.category-list {
  list-style: none;
  padding-left: 0;
}

.category-list li {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 0;
}

/* Responsive Styles */
@media screen and (max-width: 960px) {
  .menu-icon {
    display: block;
  }
  .secondary-header{
    flex-direction: column;
  }

  .nav-links{
    color: skyblue;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    background: rgba(0, 0, 0, 0.9);
  }

  .nav-menu.active {
    background: rgba(0, 0, 0, 0.9);
    left: 0;
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 10px 20px;
    width: 100%;
    display: table;
    color: skyblue;
  }

  .nav-item {
    width: 100%;
    height: 1000px;
    overflow: hidden;
  }
}
