.sticky-bar-left {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    left: 0;
    width: 1000px; /* Adjust width as needed */
    height: 100vh;
    overflow: hidden; /* Hide overflow if necessary */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8A2BE2; /* Adjust background color as needed */
  }
  
  .sticky-bar-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .sticky-bar-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensures the image covers the area */
  }
  