.holiday-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Center the video vertically */
  height: 100vh;
  background-color: #001f3f;
  overflow: hidden; /* Hide overflow to ensure the video covers the full section */
}

.trailer-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the full width and height of the section */
  border: none;
  outline: none;
}
