.footer-container {
  background-color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.footer-column {
  flex: 1;
  margin: 0 20px;
}

.footer-heading {
  font-size: 25px;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  margin-bottom: 10px;
  color: #555;
  font-size: 16px;
  cursor: pointer;
}

.footer-list li:hover {
  color: #000;
  text-decoration: underline;
}

.footer-bottom {
  text-align: center;
  font-size: 12px;
  color: #777;
  margin-top: 20px;
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
  .footer-section {
    flex-direction: column; /* Stack columns vertically */
    align-items: center; /* Center align content */
  }

  .footer-column {
    margin: 20px 0; /* Add margin for spacing between columns */
    width: 100%; /* Full width on mobile */
    text-align: center; /* Center align text */
  }

  .footer-heading {
    font-size: 20px; /* Reduce heading size for mobile */
  }

  .footer-list li {
    font-size: 14px; /* Reduce font size for list items */
  }

  .footer-container {
    padding: 20px; /* Reduce padding for mobile */
  }

  .footer-bottom {
    font-size: 14px; /* Increase footer bottom font size slightly for better readability on mobile */
  }
}
